import React from "react";


export const SponsorsSponsorComponent = ({sponsorData}) => {
	
	
	if (!sponsorData) return null;
	
	
	const {
		// partner_id: partnerID,
		partner_name: partnerName,
		partner_title: partnerTitle,
		partner_url: partnerUrl,
		partner_logo: partnerLogo,
		partner_active_status: partnerActiveStatus
	} = sponsorData;
	
	
	if (!partnerActiveStatus) return null;
	
	
	return (
		<div className={`sponsors-carousel-element`}>
		{partnerUrl ?
			<a
				href={partnerUrl}
				title={partnerTitle || partnerName}
				target={`_blank`}
				rel={`noreferrer noopener`}
				onClick={partnerUrl ? null : e => e.preventDefault()}
			>
				<img src={partnerLogo} alt={partnerName} />
			</a>
			:
			<span>
				<img src={partnerLogo} alt={partnerName} />
			</span>
		}
		</div>
	);
	
};


export default SponsorsSponsorComponent;
