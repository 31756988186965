import {CHANGE_LANGUAGE} from "@Actions/Localization";


const activeLanguageCode = localStorage.getItem("activeLanguageCode") || "PL";


export const LocalizationReducer = (state = {activeLanguage: activeLanguageCode}, action) => {
	
	
	switch (action.type) {
		
		case CHANGE_LANGUAGE:
			localStorage.setItem("activeLanguageCode", action.languageCode);
			return {...state, activeLanguage: action.languageCode};
			
		default:
			return state;
		
	}
	
	
};


export default LocalizationReducer;
