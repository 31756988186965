import React from "react";


import {Col, Alert} from "react-bootstrap";


export const FormMessageComponent = ({
	messageText = "",
	as = Alert,
	xs = 12, sm = 12, lg = 12,
	variant = "info",
	cssClasses = ""
}) => {
	
	
	cssClasses = "main-section-message" + (cssClasses ? ` ${cssClasses}` : "");
	
	
	return (
		<Col
			as={as}
			xs={xs} sm={sm} lg={lg}
			variant={variant}
			className={cssClasses}
		>
			<span>{messageText}</span>
		</Col>
	);
	
};


export default FormMessageComponent;
