import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import GLOBAL_REST_API_DATA from "@Globals/API";

import parse from "html-react-parser";


import {Redirect} from "react-router-dom";

import {Col} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import PageContainerComponent from "@Components/UI/Containers/PageContainer";
import PageHeaderContainerComponent from "@Components/UI/Containers/HeaderContainer";
import PageContentContainerComponent from "@Components/UI/Containers/ContentContainer";


const {
	URLS: {
		PAGES: {main_url: TEXT_PAGE_URL}
	}
} = GLOBAL_REST_API_DATA;


const COMPONENT_TEXTS = {
	PL: {}
};


export const TextPageComponent = ({match, textPageSlug = null}) => {
	
	
	const DISPATCHER = useDispatch();

	
	const PAGE_CONTAINER_REF = useRef(null);
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage;
	const {} = COMPONENT_TEXTS[ACTIVE_LANGUAGE];
	
	
	const TEXT_PAGE_SLUG = match?.params?.textPageSlug || textPageSlug;
	
	
	const [TEXT_PAGE_DATA, SET_TEXT_PAGE_DATA] = useState(null);
	
	
	const GET_TEXT_PAGE_DATA = async () => {
		
		if (!TEXT_PAGE_SLUG) return SET_TEXT_PAGE_DATA({error: true});
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		try {
			const RESPONSE = await fetch(`${TEXT_PAGE_URL}/${TEXT_PAGE_SLUG}/`);
			const CONTENT = await RESPONSE.json();
			SET_TEXT_PAGE_DATA(CONTENT);
		} catch (e) {
			console.log(e);
			SET_TEXT_PAGE_DATA({error: true});
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
	};
	
	useEffect(() => {
		scrollToDomElement(PAGE_CONTAINER_REF.current, 300)
		GET_TEXT_PAGE_DATA();
	}, []);
	
	
	if (!TEXT_PAGE_URL || !TEXT_PAGE_SLUG) return <Redirect to={`/404`} />;
	
	
	if (!TEXT_PAGE_DATA) return <MainContentLoaderComponent loaderColor={"#17366E"} />;
	
	if (TEXT_PAGE_DATA.error) return <Redirect to={`/404`} />;
	
	
	const pagePrefix = TEXT_PAGE_SLUG.replaceAll("-", "_");
	
	const {
		[pagePrefix + "_page_title"]: textPageTitle,
		[pagePrefix + "_page_content"]: textPageContent
	} = TEXT_PAGE_DATA[0];

	
	return (
		<PageContainerComponent forwardRef={PAGE_CONTAINER_REF} as={`main`} id={"text-page-component"}>
		
			<PageHeaderContainerComponent headerText={textPageTitle || ""} />
			
			<PageContentContainerComponent cssClasses={"text-page-component"}>
				<Col xs={12} className={"text-page-content"}>
					{parse(textPageContent || "")}
				</Col>
			</PageContentContainerComponent>
		
		</PageContainerComponent>
	);
	
};


export default TextPageComponent;
