import React from "react";


import FormTextFieldComponent from "@Elements/Sections/Forms/Fields/Text";


export const FormEmailFieldComponent = ({
	fieldValue, fieldValueHandler,
	fieldLabelText = null,
	fieldPlaceholder = "",
	fieldError = null,
	fieldDisabledStatus = false
}) => {
	
	
	return (
		<FormTextFieldComponent
			fieldType={"email"}
			fieldValue={fieldValue}
			fieldValueHandler={fieldValueHandler}
			fieldLabelText={fieldLabelText}
			fieldPlaceholder={fieldPlaceholder}
			fieldError={fieldError}
			fieldDisabledStatus={fieldDisabledStatus}
		/>
	);
	
};


export default FormEmailFieldComponent;
