import React, {useEffect, useState} from "react";


import GLOBAL_REST_API_DATA from "@Globals/API";


import {NavLink} from "react-router-dom";

import {Row, Col} from "react-bootstrap";


import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";


import mapIcon from "@Icons/map_icon.png";
import flagIcon from "@Icons/flag_icon.png";
import playerIcon from "@Icons/player_icon.png";
import playersIcon from "@Icons/players_icon.png";
import ballIcon from "@Icons/ball_icon.png";

import fbIcon from "@Icons/Socials/fb_icon.png";
import twIcon from "@Icons/Socials/tw_icon.png";
import instIcon from "@Icons/Socials/inst_icon.png";
import ytIcon from "@Icons/Socials/yt_icon.png";
import ttIcon from "@Icons/Socials/tt_icon.png";
import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


export const ApplicationFooterComponent = () => {
	
	
	const [ProjectInformation, setProjectInformation] = useState(null);
	const [ProjectInformationError, setProjectInformationError] = useState(null);
	
	
	useEffect(() => {
		
		const getProjectInformation = async () => {
		
			try {
				
				const response = await fetch(`https://superadmin.plka.pl/app/project_information`);
				
				if (response.ok) {
					const responseContent = await response.json();
					setProjectInformation(responseContent);
					console.log(responseContent);
				} else {
					setProjectInformationError({ errorCode: response.statusCode, errorMessage: "" });
				}
				
			} catch (e) {
				setProjectInformationError({ errorCode: 500, errorMessage: "" });
			}
			
		};
		
		getProjectInformation();
		
	}, []);
	
	
	if (!!ProjectInformationError) return null;
	
	
	if (!ProjectInformation) return <SingleContentLoaderComponent />;
	
	
	const {
		leagues_number: LeaguesNumber,
		teams_number: TeamsNumber,
		players_number: PlayersNumber,
		games_number: GamesNumber
	} = ProjectInformation;
	
	
	return (
		<MainSectionContainerComponent cssClasses={`application-footer-component`}>
			
			<Col as={`aside`} xs={12} lg={{span: 2}} className={`footer-project-info-label`}>
				<h5 className={`project-info-label-title`}>{`Projekt w liczbach`}</h5>
				<NavLink to={"/ligi"}>
					<img src={mapIcon} alt={``} className={`project-info-label-icon`} />
				</NavLink>
			</Col>
			
			<Col as={`aside`} xs={12} lg={{span: 6}} className={`footer-project-info-data`}>
				<Row as={`ul`} className={`project-info-list`}>
					
					<Col className={`project-info-element`}>
						<h5 className={`project-info-name`}>{`Ligi`}</h5>
						<img src={flagIcon} alt={``} className={`project-info-icon`} />
						<h6 className={`project-info-value`}>{LeaguesNumber}</h6>
					</Col>
					
					<Col className={`project-info-element`}>
						<h5 className={`project-info-name`}>{`Drużyny`}</h5>
						<img src={playersIcon} alt={``} className={`project-info-icon`} />
						<h6 className={`project-info-value`}>{TeamsNumber}</h6>
					</Col>
					
					<Col className={`project-info-element`}>
						<h5 className={`project-info-name`}>{`Zawodnicy`}</h5>
						<img src={playerIcon} alt={``} className={`project-info-icon`} />
						<h6 className={`project-info-value`}>{PlayersNumber}</h6>
					</Col>
					
					<Col className={`project-info-element`}>
						<h5 className={`project-info-name`}>{`Ilość meczów`}</h5>
						<img src={ballIcon} alt={``} className={`project-info-icon`} />
						<h6 className={`project-info-value`}>{GamesNumber}</h6>
					</Col>
					
				</Row>
			</Col>
			
			<Col as={`aside`} xs={12} lg={{span: 3}} className={`footer-project-socials`}>
				<h5 className={`project-info-label-title`}>{`Media społecznościowe`}</h5>
				<Row className={"footer-socials-list"}>
					<Col className={"footer-social"}>
						<span className={"social-name"}></span>
						<img src={fbIcon} alt={"Facebook"} className={"social-icon"} />
						<span className={"social-likes"}>{0 /*Math.ceil(Math.random() * 10000)*/}</span>
					</Col>
					<Col className={"footer-social"}>
						<span className={"social-name"}></span>
						<img src={twIcon} alt={"Twitter"} className={"social-icon"} />
						<span className={"social-likes"}>{0 /*Math.ceil(Math.random() * 10000)*/}</span>
					</Col>
					<Col className={"footer-social"}>
						<span className={"social-name"}></span>
						<img src={ytIcon} alt={"YouTube"} className={"social-icon"} />
						<span className={"social-likes"}>{0 /*Math.ceil(Math.random() * 10000)*/}</span>
					</Col>
					<Col className={"footer-social"}>
						<span className={"social-name"}></span>
						<img src={instIcon} alt={"Instagram"} className={"social-icon"} />
						<span className={"social-likes"}>{0 /*Math.ceil(Math.random() * 10000)*/}</span>
					</Col>
					<Col className={"footer-social"}>
						<span className={"social-name"}></span>
						<img src={ttIcon} alt={"Tik Tok"} className={"social-icon"} />
						<span className={"social-likes"}>{0 /*Math.ceil(Math.random() * 10000)*/}</span>
					</Col>
				</Row>
			</Col>
			
		</MainSectionContainerComponent>
	);
	
};


export default ApplicationFooterComponent;
