import {REST_API_REQUEST_STATUS} from "@Actions";


const interfaceInitialState = {
	restApiRequestStatus: false
};


export const InterfaceReducer = (state = interfaceInitialState, action) => {
	
	switch (action.type) {
	
		case REST_API_REQUEST_STATUS:
			return {...state, restApiRequestStatus: action.status || false}
		
		default:
			return state;
		
	}
	
};


export default InterfaceReducer;
