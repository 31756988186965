import React from "react";


import {Container, Row, Col} from "react-bootstrap";


import {RiseLoader} from "react-spinners";


export const MainContentLoaderComponent = ({
	loaderText = `Loading...`, loaderSize = 15, loaderElementsMargin = 2, loaderColor = "#1D4288",
	forwardRef = null
}) => {

	
	return (
		<Container fluid={true} className="main-content-loader main-content-loader-component" ref={forwardRef}>
			<Container>
				<Row>
					
					{loaderText &&
					<Col xs={12} className="loader-text" style={{marginBottom: 50, textAlign: "center", color: loaderColor}}>
						<span>{loaderText}</span>
					</Col>
					}
					
					<Col xs={12} className="loader-animation" style={{textAlign: "center"}}>
						<RiseLoader
							size={loaderSize}
							margin={loaderElementsMargin}
							color={loaderColor}
						/>
					</Col>
					
				</Row>
			</Container>
		</Container>
	);
	
};


export default MainContentLoaderComponent;
