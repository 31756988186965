import React from "react";


import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";


import HeaderLogoComponent from "@UIParts/Header/Logo";
import HeaderNavigationComponent from "@UIParts/Header/Navigation";
import HeaderSocialsComponent from "@UIParts/Header/Socials";


export const ApplicationHeaderComponent = () => {
	
	
	return (
		<MainSectionContainerComponent cssClasses={`application-header-component`}>
			
			<HeaderLogoComponent />
			
			<HeaderNavigationComponent />
			
			<HeaderSocialsComponent />
			
		</MainSectionContainerComponent>
	);
	
};


export default ApplicationHeaderComponent;
