// const API_ROOT_URL = "http://localhost:8080";
const API_ROOT_URL = "https://superadmin.plka.pl";
const REST_API_ROOT_URL = `${API_ROOT_URL}/api/v1`;


export const GLOBAL_REST_API_DATA = {
	URLS: {
		apiRootURL: REST_API_ROOT_URL,
		pingApiURL: `${API_ROOT_URL}/app/security/ping/`,
		getCsrfTokenURL: `${API_ROOT_URL}/app/security/csrf/`,
		EMAILS: {
			sendEmailURL: `${API_ROOT_URL}/app/emails/`
		},
		SOCIALS: {
			socialsLinks: `${REST_API_ROOT_URL}/socials/`
		},
		PARTNERS: {
			partners_list_page: {main_url: `${REST_API_ROOT_URL}/partners/`}
		},
		PAGES: {
			main_url: `${REST_API_ROOT_URL}/pages`,
			contactPage: `${REST_API_ROOT_URL}/pages/contact/`,
			articles_page: {
				articles_list_page: {main_url: `${REST_API_ROOT_URL}/pages/news`},
				single_article_page: {main_url: `${REST_API_ROOT_URL}/pages/news`}
			},
			join_us_page: {main_url: `${REST_API_ROOT_URL}/pages/join-us`},
			statistics_courses_page: {main_url: `${REST_API_ROOT_URL}/pages/trainings`},
		},
	}
};


export default GLOBAL_REST_API_DATA;
