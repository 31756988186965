import React, {useContext, useEffect, useState} from "react";


import GlobalDataContext from "@Contexts/Globals";


import {NavLink} from "react-router-dom";


import {Row, Col} from "react-bootstrap";


import {GiHamburgerMenu} from "react-icons/all";
import GLOBAL_REST_API_DATA from "@Globals/API";


const {URLS: {apiRootURL}} = GLOBAL_REST_API_DATA;


export const HeaderNavigationComponent = ({xs = 3, lg = 7}) => {
	
	
	const REST_API_URL = useContext(GlobalDataContext)?.restApiData?.mainRestApiURL;
	
	const [leaguesList, setLeaguesList] = useState(null);
	const [leaguesOpenStatus, setLeaguesOpenStatus] = useState(false);
	
	
	useEffect(() => {
		if (!REST_API_URL) return null;
		fetch(`${apiRootURL}/leagues/list/`)
			.then(response => response.ok ? response.json() : [{error: true, code: response.status}])
			.then(resource => setLeaguesList(resource))
			.catch(error => {return {error: true, code: 500, message: error}})
		;
		return () => {
			setLeaguesList(null);
		};
	}, [REST_API_URL]);
	
	
	const leaguesListComponent = leaguesList ?
		leaguesList.map((league, i) =>
			<Col key={i} xs={12} className={"header-navigation-league"} onClick={e => linkClickHandler(e)}>
				<span>{league.league_name}</span>
			</Col>
		)
		:
		null
	;
	
	
	const [menuOpenStatus, setMenuOpenStatus] = useState(false);
	
	
	const linkClickHandler = () => {
		console.log('Click')
		setLeaguesOpenStatus(false);
		setMenuOpenStatus(false);
	};
	
	
	return (
		<Col as={`nav`} xs={xs} lg={lg} className={`header-navigation-component`}>
		
			<span className={"header-navigation-list-switcher" + (menuOpenStatus ? " active" : "")} onClick={() => setMenuOpenStatus(!menuOpenStatus)}>
				<GiHamburgerMenu />
			</span>
			
			<Row as={`ul`} className={`header-navigation-list` + (menuOpenStatus ? " active" : "")}>
			
				<Col xs={`auto`} as={`li`} className={`header-navigation-element`}>
					<NavLink to={`/aktualnosci`} title={`Aktualności`} className={`header-navigation-link`} onClick={e => linkClickHandler(e)}>
						<span>{`Aktualności`}</span>
					</NavLink>
				</Col>
				
				{leaguesListComponent && leaguesListComponent.length > 0 &&
				<Col xs={`auto`} as={`li`} className={`header-navigation-element`}>
					<NavLink to={`/ligi`} title={`Ligi`} className={`header-navigation-link` + (leaguesOpenStatus ? " active" : "")} onClick={e => {e.preventDefault(); setLeaguesOpenStatus(!leaguesOpenStatus)}}>
						<span>{`Ligi`}</span>
					</NavLink>
					{leaguesOpenStatus &&
					<Row className={"header-navigation-leagues"}>
						<Col xs={12} className={"header-navigation-league"}>
							<NavLink to={"/ligi"} onClick={e => linkClickHandler(e)}>
								<span>{`Wszystkie ligi`}</span>
							</NavLink>
						</Col>
						{leaguesListComponent}
					</Row>
					}
				</Col>
				}
				
				<Col xs={`auto`} as={`li`} className={`header-navigation-element`}>
					<NavLink to={`/dolacz-do-nas`} title={`Dołącz do nas`} className={`header-navigation-link`} onClick={e => linkClickHandler(e)}>
						<span>{`Dołącz do nas`}</span>
					</NavLink>
				</Col>
				
				<Col xs={`auto`} as={`li`} className={`header-navigation-element`}>
					<NavLink to={`/szkolenia-statystyczne`} title={`Szkolenia Statystyczne`} className={`header-navigation-link`} onClick={e => linkClickHandler(e)}>
						<span>{`Szkolenia Statystyczne`}</span>
					</NavLink>
				</Col>
				
				{/*<Col xs={`auto`} as={`li`} className={`header-navigation-element`}>*/}
				{/*	<NavLink to={`/`} exact title={`Sklep`} className={`header-navigation-link`} onClick={e => linkClickHandler(e)}>*/}
				{/*		<span>{`Sklep`}</span>*/}
				{/*	</NavLink>*/}
				{/*</Col>*/}
				
				<Col xs={`auto`} as={`li`} className={`header-navigation-element`}>
					<NavLink to={`/partnerzy`} title={`Partnerzy`} className={`header-navigation-link`} onClick={e => linkClickHandler(e)}>
						<span>{`Partnerzy`}</span>
					</NavLink>
				</Col>
				
				<Col xs={`auto`} as={`li`} className={`header-navigation-element`}>
					<NavLink to={`/kontakt`} title={`Kontakt`} className={`header-navigation-link`} onClick={e => linkClickHandler(e)}>
						<span>{`Kontakt`}</span>
					</NavLink>
				</Col>
			
			</Row>
		
		</Col>
	);
	
};


export default HeaderNavigationComponent;
