import $ from "jquery";


export const scrollToDomElement = (target, offset = 100) => {
	
	if (!target) {
		return null;
	}
	
	$("body, html").animate(
		{
			scrollTop: $(target).offset().top - offset
		},
		1000
	);
	
}


export default scrollToDomElement;
