import React, {useEffect, useRef, useState} from "react";


import {Col, Row} from "react-bootstrap";


import {GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api';


import scrollToDomElement from "@Utilities/Scrolling";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";

import MainSectionContainerComponent from "@Elements/Sections/Containers/MainSection";
import MainSectionHeaderComponent from "@Elements/Sections/Headers/MainHeader";
import MainSectionContentComponent from "@Elements/Sections/Content/MainContent";

import LeaguesListComponent from "@UIPages/TextPages/Common/Leagues/LeaguesList";


import MAP_STYLES from "./map.json";
import GG_ICON from "@Assets/Icons/GG_SMALL.png";
import GG_ICON_M from "@Assets/Icons/GG_SMALL_M.png";


import GLOBAL_REST_API_DATA from "@Globals/API";

const {URLS: {apiRootURL: API_ROOT_URL}} = GLOBAL_REST_API_DATA;


export const LeaguesPageComponent = ({}) => {
	
	
	const REST_API_URL = API_ROOT_URL;
	
	
	const LEAGUES_PAGE_COMPONENT_REF = useRef(null);
	
	useEffect(() => {
			scrollToDomElement(LEAGUES_PAGE_COMPONENT_REF.current);
	}, []);
	
	
	const [LEAGUES_LIST, SET_LEAGUES_LIST] = useState(null);
	
	useEffect(() => {
		
		const LEAGUES_LIST_API_URL = `${REST_API_URL}/leagues/list/`;
		
		fetch(LEAGUES_LIST_API_URL)
			.then(response => response.ok ? response.json() : [{error: true, code: response.status}])
			.then(resource => SET_LEAGUES_LIST(resource))
			.catch(error => {return {error: true, code: 500, message: error}})
		;
		
		return () => SET_LEAGUES_LIST(null);
		
	}, []);
	
	
	const GOOGLE_MAP_REF = useRef(null);
	
	const {isLoaded: MAP_LOADED_STATUS} = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyDIH0ikRFe6yGyi68nLEumr0evrbg5fjTM"
	});
	
	
	if (!LEAGUES_LIST) return <MainContentLoaderComponent loaderElementsMargin={10} loaderText={`Ligi`} />;
	
	
	const MAP_LEAGUES_LIST = LEAGUES_LIST.map((localization, i) => {
		
		const {
			league_city: LEAGUE_CITY,
			league_latitude: LEAGUE_LATITUDE,
			league_longitude: LEAGUE_LONGITUDE,
			league_name: LEAGUE_NAME,
			league_licences: LEAGUE_LICENCES,
			league_active_status: LEAGUE_ACTIVE_STATUS
		} = localization;
		
		const MARKER_ICON = LEAGUE_ACTIVE_STATUS ? GG_ICON : GG_ICON_M;
		
		return (
			<Marker
				key={i}
				position={{lat: LEAGUE_LATITUDE, lng: LEAGUE_LONGITUDE}}
				icon={MARKER_ICON}
				title={`${LEAGUE_NAME} (${LEAGUE_CITY})`}
			/>
		)
	});
	
	
	return (
		<MainSectionContainerComponent forwardRef={LEAGUES_PAGE_COMPONENT_REF} as={`main`} cssClasses={`leagues-page-component`}>
			
			<MainSectionHeaderComponent headerText={"Ligi"} />
			
			<MainSectionContentComponent>
				
				<Row className="map-section-map" style={{margin: 0, width: "100%"}}>
					
					<LeaguesListComponent
						LEAGUES={LEAGUES_LIST}
					/>
					
					<Col xs={12} lg={9} style={{minHeight: window.innerWidth > 992 ? 900 : window.innerWidth - 30}}>
						{MAP_LOADED_STATUS &&
						<GoogleMap
							ref={GOOGLE_MAP_REF}
							mapContainerStyle={{
								width: "100%",
								height: "100%",
								border: "2px solid #CED4DA"
							}}
							center={{lat: 52.00, lng: 19.21}}
							zoom={window.innerWidth > 992 ? 7 : 5.2}
							options={{styles: MAP_STYLES}}
							fullscreenControl={false}
						>
							{MAP_LEAGUES_LIST}
						</GoogleMap>
						}
						{!MAP_LOADED_STATUS && <MainContentLoaderComponent />}
					</Col>
					
				</Row>
				
			</MainSectionContentComponent>
		
		</MainSectionContainerComponent>
	);
	
};


export default LeaguesPageComponent;
