import React from "react";


import {Button} from "react-bootstrap";


export const FormSubmitButtonComponent = ({
	buttonText,
	buttonClickHandler,
	buttonDisabledStatus
}) => {
	
	
	return (
		<Button
			type={`button`}
			variant={"primary"}
			onClick={buttonClickHandler}
			disabled={buttonDisabledStatus}
		>
			<span>{buttonText}</span>
		</Button>
	);
	
};


export default FormSubmitButtonComponent;
