import React, {useState} from "react";

import {useSelector} from "react-redux";

import {Button, Col, Form, Row} from "react-bootstrap";

import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";


export const CreateNewLeagueFormComponent = ({sendMessageRequestHandler}) => {
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus;
	
	
	const [createLeagueFormErrors, setCreateLeagueFormErrors] = useState({});
	
	
	const [createLeagueFormVillage, setCreateLeagueFormVillage] = useState("");
	const [createLeagueFormPlayedBasketball, setCreateLeagueFormPlayedBasketball] = useState(0);
	const [createLeagueFormKnowRules, setCreateLeagueFormKnowRules] = useState(0);
	const [createLeagueFormKnowRulesLevel, setCreateLeagueFormKnowRulesLevel] = useState("0");
	const [createLeagueFormEventsExperience, setCreateLeagueFormEventsExperience] = useState("");
	const [createLeagueFormTimeToSpent, setCreateLeagueFormTimeToSpent] = useState("");
	const [createLeagueFormExtraOrFullTime, setCreateLeagueFormExtraOrFullTime] = useState(0);
	const [createLeagueFormComputerKnowledge, setCreateLeagueFormComputerKnowledge] = useState(0);
	const [createLeagueFormStatisticsKnowledge, setCreateLeagueFormStatisticsKnowledge] = useState("");
	const [createLeagueFormFirstName, setCreateLeagueFormFirstName] = useState("");
	const [createLeagueFormLastName, setCreateLeagueFormLastName] = useState("");
	const [createLeagueFormEmailAddress, setCreateLeagueFormEmailAddress] = useState("");
	const [createLeagueFormPhoneNumber, setCreateLeagueFormPhoneNumber] = useState("");
	
	
	const changeCreateLeagueFormVillage = (village) => {
		if (village.length > 2) setCreateLeagueFormErrors({...createLeagueFormErrors, formVillage: null});
		setCreateLeagueFormVillage(village);
	};
	
	const changeCreateLeagueFormPlayedBasketball = (status) => {
		if (status === 1 || status === 2) setCreateLeagueFormErrors({...createLeagueFormErrors, formPlayedBasketball: null});
		setCreateLeagueFormPlayedBasketball(status);
	};
	
	const changeCreateLeagueFormKnowRules = (status) => {
		if (status === 1 || status === 2) setCreateLeagueFormErrors({...createLeagueFormErrors, formRulesKnowledge: null});
		setCreateLeagueFormKnowRules(status);
	};
	
	const changeCreateLeagueFormEventsExperience = (text) => {
	    if (text.length > 5) setCreateLeagueFormErrors({...createLeagueFormErrors, formEventsExperience: null});
		setCreateLeagueFormEventsExperience(text);
	};
	
	const changeCreateLeagueFormTimeToSpent = (text) => {
		if (text.length > 5) setCreateLeagueFormErrors({...createLeagueFormErrors, formTimeToSpent: null});
		setCreateLeagueFormTimeToSpent(text);
	};
	
	const changeCreateLeagueFormExtraOrFullTime = (status) => {
		if (status === 1 || status === 2) setCreateLeagueFormErrors({...createLeagueFormErrors, formTimeType: null});
		setCreateLeagueFormExtraOrFullTime(status);
	};
	
	const changeCreateLeagueFormComputerKnowledge = (status) => {
		if (status === 1 || status === 2 || status === 3) setCreateLeagueFormErrors({...createLeagueFormErrors, formComputerKnowledge: null});
		setCreateLeagueFormComputerKnowledge(status);
	};
	
	const changeCreateLeagueFormStatisticsKnowledge = (text) => {
		if (text.length > 5) setCreateLeagueFormErrors({...createLeagueFormErrors, formStatisticsKnowledge: null});
		setCreateLeagueFormStatisticsKnowledge(text);
	};
	
	const changeCreateLeagueFormFirstName = (text) => {
		if (text.length > 2) setCreateLeagueFormErrors({...createLeagueFormErrors, formFirstName: null});
		setCreateLeagueFormFirstName(text);
	};
	
	const changeCreateLeagueFormLastName = (text) => {
		if (text.length > 2) setCreateLeagueFormErrors({...createLeagueFormErrors, formLastName: null});
		setCreateLeagueFormLastName(text);
	};
	
	const changeCreateLeagueFormEmailAddress = (email) => {
	    if (isEmail(email)) setCreateLeagueFormErrors({...createLeagueFormErrors, formEmailAddress: null});
	    setCreateLeagueFormEmailAddress(email);
	};
	
	const changeCreateLeagueFormPhoneNumber = (phone) => {
		if (isMobilePhone(phone)) setCreateLeagueFormErrors({...createLeagueFormErrors, formPhoneNumber: null});
		setCreateLeagueFormPhoneNumber(phone);
	};
	
	
	const submitCreateNewLeagueForm = async () => {
	
		const formErrors = {};
		
		if (createLeagueFormVillage.length < 3) formErrors.formVillage = "Podana nazwa jest zbyt krótka";
		if (createLeagueFormPlayedBasketball !== 1 && createLeagueFormPlayedBasketball !== 2) formErrors.formPlayedBasketball = "Proszę zaznaczyć odpowiedź";
		if (createLeagueFormKnowRules !== 1 && createLeagueFormKnowRules !== 2) formErrors.formRulesKnowledge = "Proszę zaznaczyć odpowiedź";
		if (createLeagueFormEventsExperience.length < 6) formErrors.formEventsExperience = "Podana odpowiedź jest zbyt krótka"
		if (createLeagueFormTimeToSpent.length < 6) formErrors.formTimeToSpent = "Podana odpowiedź jest zbyt krótka"
		if (createLeagueFormExtraOrFullTime !== 1 && createLeagueFormExtraOrFullTime !== 2) formErrors.formTimeType = "Proszę zaznaczyć odpowiedź";
		if (createLeagueFormComputerKnowledge !== 1 && createLeagueFormComputerKnowledge !== 2 && createLeagueFormComputerKnowledge !== 3) formErrors.formComputerKnowledge = "Proszę zaznaczyć odpowiedź";
		if (createLeagueFormStatisticsKnowledge.length < 6) formErrors.formStatisticsKnowledge = "Podana odpowiedź jest zbyt krótka";
		if (createLeagueFormFirstName.length < 3) formErrors.formFirstName = "Podana odpowiedź jest zbyt krótka";
		if (createLeagueFormLastName.length < 3) formErrors.formLastName = "Podana odpowiedź jest zbyt krótka";
		if (!isEmail(createLeagueFormEmailAddress)) formErrors.formEmailAddress = "Email jest nieprawidłowy";
		if (!isMobilePhone(createLeagueFormPhoneNumber)) formErrors.formPhoneNumber = "Numer Telefonu jest nieprawidłowy";
		
		setCreateLeagueFormErrors(formErrors);
		
		if (Object.entries(formErrors).length) return null;
		
		const createNewLeagueResponse = await sendMessageRequestHandler({
			leagueVillage: createLeagueFormVillage,
			basketballPlayedStatus: createLeagueFormPlayedBasketball,
			rulesKnowledgeStatus: createLeagueFormKnowRules,
			rulesKnowledgeStatusLevel: createLeagueFormKnowRulesLevel,
			eventsExperience: createLeagueFormEventsExperience,
			timeToSpentForLeague: createLeagueFormTimeToSpent,
			timeSpentType: createLeagueFormExtraOrFullTime,
			computerKnowledgeLevel: createLeagueFormComputerKnowledge,
			statisticsKnowledge: createLeagueFormStatisticsKnowledge,
			firstName: createLeagueFormFirstName, lastName: createLeagueFormLastName,
			emailAddress: createLeagueFormEmailAddress, phoneNumber: createLeagueFormPhoneNumber
		});
		
		console.log(createNewLeagueResponse)
		
	};
	
	
	return (
		<Col xs={12} className={"create-new-league-form"}>
			<Row className={"create-new-league-form-content"}>
				
				<Col as={Form.Group} xs={12} className={"create-new-league-field league-village full-width-field"}>
					<Form.Label>{`Gdzie chcesz założyć ligę (miejscowość)`}</Form.Label>
					<Form.Control
						placeholder={`Miejscowość`}
						value={createLeagueFormVillage}
						onChange={e => changeCreateLeagueFormVillage(e.target.value)}
						className={"" + (createLeagueFormErrors?.formVillage ? " error" : "")}
						disabled={REST_API_REQUEST_STATUS}
					/>
					{createLeagueFormErrors?.formVillage &&
					<Form.Label className={"error"}>{createLeagueFormErrors?.formVillage}</Form.Label>
					}
				</Col>
				
				<Col as={Form.Group} xs={12} lg={4} className={"create-new-league-field radio-field basketball-played-status text-center"}>
					<Form.Label>{`Czy grasz \\ grałeś w koszykówkę`}</Form.Label>
					<Row className={"radio-field-options"}>
						<Col xs={6} lg={6} className={"radio-field-option"}>
							<Form.Label
								className={createLeagueFormPlayedBasketball === 1 ? " checked" : ""}
								onClick={() => changeCreateLeagueFormPlayedBasketball(createLeagueFormPlayedBasketball === 1 ? null : 1)}
								disabled={REST_API_REQUEST_STATUS}
							>{`TAK`}</Form.Label>
						</Col>
						<Col xs={6} lg={6} className={"radio-field-option"}>
							<Form.Label
								className={createLeagueFormPlayedBasketball === 2 ? " checked" : ""}
								onClick={() => changeCreateLeagueFormPlayedBasketball(createLeagueFormPlayedBasketball === 2 ? null : 2)}
								disabled={REST_API_REQUEST_STATUS}
							>{`NIE`}</Form.Label>
						</Col>
					</Row>
					{createLeagueFormErrors?.formPlayedBasketball &&
					<Form.Label className={"error"}>{createLeagueFormErrors?.formPlayedBasketball}</Form.Label>
					}
				</Col>
				
				<Col as={Form.Group} xs={12} lg={4} className={"create-new-league-field rules-knowledge-status radio-field text-center"}>
					<Form.Label>{`Czy znasz przepisy gry w koszykówkę`}</Form.Label>
					<Row className={"radio-field-options"}>
						<Col xs={6} lg={6} className={"radio-field-option"}>
							<Form.Label
								className={createLeagueFormKnowRules === 1 ? " checked" : ""}
								onClick={() => changeCreateLeagueFormKnowRules(createLeagueFormKnowRules === 1 ? null : 1)}
								disabled={REST_API_REQUEST_STATUS}
							>{`TAK`}</Form.Label>
						</Col>
						<Col xs={6} lg={6} className={"radio-field-option"}>
							<Form.Label
								className={createLeagueFormKnowRules === 2 ? " checked" : ""}
								onClick={() => changeCreateLeagueFormKnowRules(createLeagueFormKnowRules === 2 ? null : 2)}
								disabled={REST_API_REQUEST_STATUS}
							>{`NIE`}</Form.Label>
						</Col>
					</Row>
					{createLeagueFormErrors?.formRulesKnowledge &&
					<Form.Label className={"error"}>{createLeagueFormErrors?.formRulesKnowledge}</Form.Label>
					}
				</Col>
				
				{createLeagueFormKnowRules === 1 &&
				<Col as={Form.Group} xs={12} lg={4}
				     className={"create-new-league-field rules-knowledge-level text-center"}>
					<Form.Label>{`Na jaki poziom oceniasz swoją znajomość zasad`}</Form.Label>
					<Form.Control
						as={"select"}
						value={createLeagueFormKnowRulesLevel}
						onChange={e => setCreateLeagueFormKnowRulesLevel(e.target.value)}
						disabled={REST_API_REQUEST_STATUS}
					>
						<option value={"0"}>{`Nie umiem ocenić`}</option>
						<option value={"1"}>{`1`}</option>
						<option value={"2"}>{`2`}</option>
						<option value={"3"}>{`3`}</option>
						<option value={"4"}>{`4`}</option>
						<option value={"5"}>{`5`}</option>
						<option value={"6"}>{`6`}</option>
						<option value={"7"}>{`7`}</option>
						<option value={"8"}>{`8`}</option>
						<option value={"9"}>{`9`}</option>
						<option value={"10"}>{`10`}</option>
					</Form.Control>
				</Col>
				}
				
				<Col as={Form.Group} xs={12} className={"create-new-league-field events-organization-experience"}>
					<Form.Label>{`Jakie masz doświadczenia z organizacją imprez sportowych`}</Form.Label>
					<Form.Control
						as={"textarea"}
						placeholder={`Twoje doświadczenia`}
						value={createLeagueFormEventsExperience}
						onChange={e => changeCreateLeagueFormEventsExperience(e.target.value)}
						className={"" + (createLeagueFormErrors?.formEventsExperience ? " error" : "")}
						disabled={REST_API_REQUEST_STATUS}
					/>
					{createLeagueFormErrors?.formEventsExperience &&
					<Form.Label className={"error"}>{createLeagueFormErrors?.formEventsExperience}</Form.Label>
					}
				</Col>
				
				<Col as={Form.Group} xs={12} lg={6} className={"create-new-league-field time-spent-value"}>
					<Form.Label>{`Ile czasu jesteś w stanie poświęcić na działalność ligi `}</Form.Label>
					<Form.Control
						placeholder={`Oszacuj ilość czasu`}
						value={createLeagueFormTimeToSpent}
						onChange={e => changeCreateLeagueFormTimeToSpent(e.target.value)}
						className={"" + (createLeagueFormErrors?.formTimeToSpent ? " error" : "")}
						disabled={REST_API_REQUEST_STATUS}
					/>
					{createLeagueFormErrors?.formTimeToSpent &&
					<Form.Label className={"error"}>{createLeagueFormErrors?.formTimeToSpent}</Form.Label>
					}
				</Col>
				
				<Col as={Form.Group} xs={12} lg={6} className={"create-new-league-field radio-field text-center job-time-type"}>
					<Form.Label>{`Czy będzie to Twoje główne czy dodatkowe zajęcie?`}</Form.Label>
					<Row className={"radio-field-options"}>
						<Col xs={6} lg={6} className={"radio-field-option"}>
							<Form.Label disabled={REST_API_REQUEST_STATUS} className={createLeagueFormExtraOrFullTime === 1 ? " checked" : ""} onClick={() => changeCreateLeagueFormExtraOrFullTime(createLeagueFormExtraOrFullTime === 1 ? null : 1)}>{`Główne`}</Form.Label>
						</Col>
						<Col xs={6} lg={6} className={"radio-field-option"}>
							<Form.Label disabled={REST_API_REQUEST_STATUS} className={createLeagueFormExtraOrFullTime === 2 ? " checked" : ""} onClick={() => changeCreateLeagueFormExtraOrFullTime(createLeagueFormExtraOrFullTime === 2 ? null : 2)}>{`Dodatkowe`}</Form.Label>
						</Col>
					</Row>
					{createLeagueFormErrors?.formTimeType &&
					<Form.Label className={"error"}>{createLeagueFormErrors?.formTimeType}</Form.Label>
					}
				</Col>
				
				<Col as={Form.Group} xs={12} className={"create-new-league-field radio-field text-center computer-knowledge-level"}>
					<Form.Label>{`Jak oceniasz swoje umiejętności obsługi komputera?`}</Form.Label>
					<Row className={"radio-field-options"}>
						<Col xs={12} lg={{span: 3, offset: 1}} className={"radio-field-option text-center"}>
							<Form.Label disabled={REST_API_REQUEST_STATUS} className={createLeagueFormComputerKnowledge === 1 ? " checked" : ""} onClick={() => changeCreateLeagueFormComputerKnowledge(createLeagueFormComputerKnowledge === 1 ? null : 1)}>{`podstawowy`}</Form.Label>
						</Col>
						<Col xs={12}  lg={4} className={"radio-field-option text-center"}>
							<Form.Label disabled={REST_API_REQUEST_STATUS} className={createLeagueFormComputerKnowledge === 2 ? " checked" : ""} onClick={() => changeCreateLeagueFormComputerKnowledge(createLeagueFormComputerKnowledge === 2 ? null : 2)}>{`średniozaawansowany `}</Form.Label>
						</Col>
						<Col xs={12} lg={3} className={"radio-field-option text-center"}>
							<Form.Label disabled={REST_API_REQUEST_STATUS} className={createLeagueFormComputerKnowledge === 3 ? " checked" : ""} onClick={() => changeCreateLeagueFormComputerKnowledge(createLeagueFormComputerKnowledge === 3 ? null : 3)}>{`zaawansowany  `}</Form.Label>
						</Col>
					</Row>
					{createLeagueFormErrors?.formComputerKnowledge &&
					<Form.Label className={"error"}>{createLeagueFormErrors?.formComputerKnowledge}</Form.Label>
					}
				</Col>
				
				<Col as={Form.Group} xs={12} className={"create-new-league-field statistics-knowledge"}>
					<Form.Label>{`Czy kiedykolwiek robiłeś statystyki na meczu koszykówki lub miałeś z nimi styczność?`}</Form.Label>
					<Form.Control
						as={"textarea"}
						placeholder={`Twoje doświadczenia`}
						value={createLeagueFormStatisticsKnowledge}
						onChange={e => changeCreateLeagueFormStatisticsKnowledge(e.target.value)}
						className={"" + (createLeagueFormErrors?.formStatisticsKnowledge ? " error" : "")}
						disabled={REST_API_REQUEST_STATUS}
					/>
					{createLeagueFormErrors?.formStatisticsKnowledge &&
					<Form.Label className={"error"}>{createLeagueFormErrors?.formStatisticsKnowledge}</Form.Label>
					}
				</Col>
				
				<Col as={Form.Group} xs={12} className={"create-new-league-field personal-data"}>
					<Form.Label>{`Podaj swoje: imię, nazwisko, mail, oraz numer telefonu`}</Form.Label>
					<Row className={"create-new-league-field-content"}>
						<Col xs={12} lg={6} className={"create-new-league-field"}>
							<Form.Control
								placeholder={`Imię`}
								value={createLeagueFormFirstName}
								onChange={e => changeCreateLeagueFormFirstName(e.target.value)}
								className={"" + (createLeagueFormErrors?.formFirstName ? " error" : "")}
								disabled={REST_API_REQUEST_STATUS}
							/>
							{createLeagueFormErrors?.formFirstName &&
							<Form.Label className={"error"}>{createLeagueFormErrors?.formFirstName}</Form.Label>
							}
						</Col>
						<Col xs={12} lg={6} className={"create-new-league-field"}>
							<Form.Control
								placeholder={`Nazwisko`}
								value={createLeagueFormLastName}
								onChange={e => changeCreateLeagueFormLastName(e.target.value)}
								className={"" + (createLeagueFormErrors?.formLastName ? " error" : "")}
								disabled={REST_API_REQUEST_STATUS}
							/>
							{createLeagueFormErrors?.formLastName &&
							<Form.Label className={"error"}>{createLeagueFormErrors?.formLastName}</Form.Label>
							}
						</Col>
					</Row>
					<Row className={"create-new-league-field-content"}>
						<Col xs={12} lg={6} className={"create-new-league-field"}>
							<Form.Control
								placeholder={`Email`}
								value={createLeagueFormEmailAddress}
								onChange={e => changeCreateLeagueFormEmailAddress(e.target.value)}
								className={"" + (createLeagueFormErrors?.formEmailAddress ? " error" : "")}
								disabled={REST_API_REQUEST_STATUS}
							/>
							{createLeagueFormErrors?.formEmailAddress &&
							<Form.Label className={"error"}>{createLeagueFormErrors?.formEmailAddress}</Form.Label>
							}
						</Col>
						<Col xs={12} lg={6} className={"create-new-league-field"}>
							<Form.Control
								placeholder={`Numer Telefonu`}
								value={createLeagueFormPhoneNumber}
								onChange={e => changeCreateLeagueFormPhoneNumber(e.target.value)}
								className={"" + (createLeagueFormErrors?.formPhoneNumber ? " error" : "")}
								disabled={REST_API_REQUEST_STATUS}
							/>
							{createLeagueFormErrors?.formPhoneNumber &&
							<Form.Label className={"error"}>{createLeagueFormErrors?.formPhoneNumber}</Form.Label>
							}
						</Col>
					</Row>
					
				</Col>
				
				<Col xs={12} className={"create-new-league-field text-center"}>
					<Button variant={"primary"} onClick={() => submitCreateNewLeagueForm()} disabled={REST_API_REQUEST_STATUS}>
						<span>{`Wyślij`}</span>
					</Button>
				</Col>
				
			</Row>
		</Col>
	);
	
};


export default CreateNewLeagueFormComponent;
