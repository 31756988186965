import React from "react";


import {Col, Row} from "react-bootstrap";


export const JoinUsTypeSwitcherComponent = ({joinUsSelectedOrderType, joinUsSelectedOrderTypeHandler}) => {
	
	
	return (
		<Col xs={12} className={"join-us-type-switcher section-option-switcher"}>
			<Row className={"join-us-type-switcher-content"}>
				
				<Col xs={6} className={"type-switcher-type" + (joinUsSelectedOrderType === 1 ? " selected" : "")} onClick={() => joinUsSelectedOrderTypeHandler(1)}>
					<span>{`Mam ligę`}</span>
				</Col>
				
				<Col xs={6} className={"type-switcher-type" + (joinUsSelectedOrderType === 2 ? " selected" : "")} onClick={() => joinUsSelectedOrderTypeHandler(2)}>
					<span>{`Chcę założyć ligę`}</span>
				</Col>
				
			</Row>
		</Col>
	);
	
};


export default JoinUsTypeSwitcherComponent;
