import React from "react";


import {Col, Container, Row} from "react-bootstrap";


export const ArticlePageImageComponent = ({articleImage = null, articleTitle = ""}) => {
	
	
	if (!articleImage) return null;
	
	
	return (
		<Container fluid={true} className={`article-page-image-component`}>
			<Container className={`article-page-image-container`}>
				<Row className={`article-page-image-content`}>
					<Col xs={12} as={"picture"} className={`article-page-image-picture`}>
						<img src={articleImage} alt={articleTitle} className={`article-page-image`}/>
					</Col>
				</Row>
			</Container>
		</Container>
	);
	
};


export default ArticlePageImageComponent;
