import React from "react";

import parse from "html-react-parser";


import {NavLink} from "react-router-dom";


import {Row, Col} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import noImagePicture from "@Images/no-image.png";


export const NewsActiveArticleComponent = ({activeArticleData = null}) => {
	
	
	if (!activeArticleData) return <MainContentLoaderComponent loaderColor={"#FFF"} />;
	
	
	const {
		articleSlug, articleTitle, articleShortContent, articleImage
	} = activeArticleData;
	
	
	const articleURL = articleSlug ? `/aktualnosci/${articleSlug}` : null;
	
	
	return (
		<Col xs={12} className={"home-page-active-article"}>
			<Row className={"home-page-active-article-content"}>
				
				<Col xs={12} lg={4} className={"active-article-texts"}>
					<Row className={"active-article-texts-content"}>
						<Col xs={12} className={"active-article-title"}>
							<NavLink to={articleURL} title={articleTitle} onClick={articleURL ? null : e => e.preventDefault()}>
								<span>{articleTitle}</span>
							</NavLink>
						</Col>
						<Col xs={12} className={"active-article-short-content"}>
							<NavLink to={articleURL} title={articleTitle} onClick={articleURL ? null : e => e.preventDefault()}>
								{parse(articleShortContent || "")}
							</NavLink>
						</Col>
						<Col xs={12} className={"active-article-read-more"}>
							<NavLink to={articleURL} title={`Przejdź do artykułu`} onClick={articleURL ? null : e => e.preventDefault()}>
								<span>{`Czytaj więcej`}</span>
							</NavLink>
						</Col>
					</Row>
				</Col>
				
				<Col xs={12} lg={8} className={"active-article-image"}>
					<Row className={"active-article-image-picture"}>
						<NavLink to={articleURL} title={articleTitle} onClick={articleURL ? null : e => e.preventDefault()}>
							<img src={articleImage || noImagePicture} alt={articleTitle} />
						</NavLink>
					</Row>
				</Col>
				
			</Row>
		</Col>
	);
	
};


export default NewsActiveArticleComponent;
