import React, {useEffect} from "react";

import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";


import ApplicationHeaderComponent from "@UIParts/Header";
import ApplicationFooterComponent from "@UIParts/Footer";
import ApplicationSponsorsComponent from "@UIParts/Sponsors";
import ApplicationCopyrightsComponent from "@UIParts/Copyrights";

import HomePageComponent from "@UIPages/Common/HomePage";
import ContactPageComponent from "@UIPages/Common/ContactPage";

import PartnersPageComponent from "@UIPages/TextPages/Common/Partners";
// import LeaguesPageComponent from "@UIPages/TextPages/Common/Leagues";

import ArticlesPageComponent from "@UIPages/TextPages/Articles";
import ArticlePageComponent from "@UIPages/TextPages/Articles/Article";

import TextPageComponent from "@UIPages/Common/TextPage";

import E404PageComponent from "@UIPages/_Errors/404";
import JoinUsPageComponent from "@UIPages/Common/JoinUsPage";
import {useDispatch, useSelector} from "react-redux";
import getCookie from "@Utilities/Cookies/GetCookie";
import {SET_CSRF_COOKIE_ACTION} from "@Actions";
import getApiCsrfTokenRequest from "@Utilities/Requests/CSRF";
import GlobalApiRequestLoaderComponent from "@Components/UI/Loaders/GlobalApiRequest";
import LeaguesPageComponent from "@UIPages/TextPages/Common/Leagues";
import CoursesPageComponent from "@UIPages/Common/CoursesPage";


export const Application = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus;
	
	
	const GET_CSRF_COOKIE = async () => {
		let csrfCookie = await getCookie("csrftoken");
		if (!csrfCookie) {
			try {
				csrfCookie = await getApiCsrfTokenRequest();
				csrfCookie = await getCookie("csrftoken") || csrfCookie;
			} catch (e) {
				csrfCookie = null;
			}
		}
		DISPATCHER(SET_CSRF_COOKIE_ACTION(csrfCookie));
	};
	
	useEffect(() => GET_CSRF_COOKIE(), []);
	
	
	return (
		<Router>
			
			<Route path={`/`} render={(props) => <ApplicationHeaderComponent {...props} />} />
			
			<Switch>
				
				<Route
					path={`/`} exact
					render={(props) =>
						<HomePageComponent {...props} />
					}
				/>
				
				<Route path={`/kontakt`} exact render={(props) => <ContactPageComponent {...props} />} />
				
				<Route path={`/aktualnosci/:articleSlug`} exact render={(props) => <ArticlePageComponent {...props} />} />
				<Route path={`/aktualnosci`} exact render={(props) => <ArticlesPageComponent {...props} />} />
				
				<Route path={`/dolacz-do-nas`} exact render={(props) => <JoinUsPageComponent {...props} />} />
				<Route path={`/szkolenia-statystyczne`} exact render={(props) => <CoursesPageComponent {...props} pageSLug={`trainings`} />} />
				
				<Route path={`/partnerzy`} exact render={(props) => <PartnersPageComponent {...props} />} />
				<Route path={`/ligi`} exact render={(props) => <LeaguesPageComponent {...props} />} />
				
				<Route path={`/polityka-prywatnosci`} exact render={(props) => <TextPageComponent {...props} textPageSlug={`privacy-policy`} />} />
				<Route path={`/regulamin`} exact render={(props) => <TextPageComponent {...props} textPageSlug={`regulations`} />} />
				
				<Route path={`/404`} exact render={(props) => <E404PageComponent {...props} />} />
				
				<Redirect to={`/404`} />
				
			</Switch>
			
			<Route path={`/`} render={(props) => <ApplicationSponsorsComponent {...props} />} />
			<Route path={`/`} render={(props) => <ApplicationFooterComponent {...props} />} />
			<Route path={`/`} render={(props) => <ApplicationCopyrightsComponent {...props} />} />
			
			{!!REST_API_REQUEST_STATUS &&
			<GlobalApiRequestLoaderComponent />
			}
			
		</Router>
	);
	
};


export default Application;
