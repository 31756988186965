import React from "react";


import {Col} from "react-bootstrap";


import {PulseLoader} from "react-spinners";


export const SingleContentLoaderComponent = ({
	xs = 12, sm = 12, lg = 12, xl = 12,
	loaderSize = 15, loaderColor = "#1D4288", loaderElementsMargin = 3,
	forwardRef = null,
	cssClasses = ""
}) => {

	
	cssClasses = "single-content-loader text-center" + (cssClasses ? ` ${cssClasses}` : "");
	
	
	return (
		<Col xs={xs} sm={sm} lg={lg} xl={xl} className={cssClasses} ref={forwardRef}>
			<PulseLoader size={loaderSize} color={loaderColor} margin={loaderElementsMargin} />
			<PulseLoader size={loaderSize} color={loaderColor} margin={loaderElementsMargin} />
		</Col>
	);
	
};


export default SingleContentLoaderComponent;
