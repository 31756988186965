import React, {useEffect, useState} from "react";


import MainSectionHeaderComponent from "@Elements/Sections/Headers/MainHeader";
import MainSectionTextComponent from "@Elements/Sections/Content/TextContent";
import GLOBAL_REST_API_DATA from "@Globals/API";
import scrollToDomElement from "@Utilities/Scrolling";


const {URLS: {PAGES: {contactPage: CONTACT_PAGE_URL}}} = GLOBAL_REST_API_DATA;


export const ContactPageContentComponent = ({}) => {
	
	
	const [CONTACT_PAGE_DATA, SET_CONTACT_PAGE_DATA] = useState(null);
	
	const GET_CONTACT_PAGE_DATA = async () => {
		try {
			const response = await fetch(CONTACT_PAGE_URL);
			const content = await response.json();
			SET_CONTACT_PAGE_DATA(content[0]);
			console.log(content)
		} catch (e) {
			console.log(e);
			SET_CONTACT_PAGE_DATA({error: true});
		}
	};
	
	useEffect(() => {
		if (!CONTACT_PAGE_URL) return null;
		GET_CONTACT_PAGE_DATA();
	}, [CONTACT_PAGE_URL]);
	
	
	if (!CONTACT_PAGE_DATA || CONTACT_PAGE_DATA.error) return null;
	
	
	const {
		contact_page_title: contactPageTitle, contact_page_content: contactPageContent
	} = CONTACT_PAGE_DATA || {};
	
	
	return (
		<>
			
			<MainSectionHeaderComponent
				headerText={contactPageTitle}
			/>
			
			<MainSectionTextComponent
				lg={4} xl={4}
				textContent={contactPageContent}
			/>
			
		</>
	)
	
	
};


export default ContactPageContentComponent;
