import React from "react";


import {Container, Row} from "react-bootstrap";


export const MainSectionContainerComponent = ({children, cssClasses = "", as = "section", forwardRef = null, props}) => {
	
	
	const classes = `main-section-component` + (cssClasses ? ` ${cssClasses}` : "");
	
	return (
		<Container as={as} {...props} fluid={true} className={classes} ref={forwardRef}>
			<Container className={`main-section-container`}>
				<Row className={`main-section-content`}>
					{children}
				</Row>
			</Container>
		</Container>
	);
	
};


export default MainSectionContainerComponent;
