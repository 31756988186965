import React, {useEffect, useRef} from "react";

import {useSelector} from "react-redux";


import scrollToDomElement from "@Utilities/Scrolling";


import PageContainerComponent from "@Components/UI/Containers/PageContainer";
import PageHeaderContainerComponent from "@Components/UI/Containers/HeaderContainer";
import PageContentContainerComponent from "@Components/UI/Containers/ContentContainer";


import ArticlesListPageComponent from "@UIPages/TextPages/Articles/List";


const COMPONENT_TEXTS = {
	PL: {}
};


export const ArticlesPageComponent = () => {
	
	
	const PAGE_CONTAINER_REF = useRef(null);
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage;
	const {} = COMPONENT_TEXTS[ACTIVE_LANGUAGE];
	
	
	useEffect(() => {
		scrollToDomElement(PAGE_CONTAINER_REF.current, 300);
	}, []);
	
	
	return (
		<PageContainerComponent forwardRef={PAGE_CONTAINER_REF} id={"articles-page-component"}>
			
			<PageHeaderContainerComponent headerText={"Aktualności"}  />
			
			<PageContentContainerComponent cssClasses={`articles-page-component`}>
				<ArticlesListPageComponent />
			</PageContentContainerComponent>
			
		</PageContainerComponent>
	);
	
};


export default ArticlesPageComponent;
