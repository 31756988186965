import React from "react";


import {Form} from "react-bootstrap";


export const FormTextAreaFieldComponent = ({
	fieldValue, fieldValueHandler,
	fieldLabelText = null,
	cssClasses = "",
	fieldPlaceholder = "",
	fieldError = null,
	fieldDisabledStatus = false,
	props
}) => {
	
	
	return (
	<>
		
		{fieldLabelText &&
		<Form.Label>{fieldLabelText}</Form.Label>
		}
		
		<Form.Control
			as={"textarea"}
			placeholder={fieldPlaceholder || fieldLabelText}
			value={fieldValue}
			onChange={fieldValueHandler}
			className={cssClasses + (fieldError ? " error" : "")}
			disabled={fieldDisabledStatus}
			{...props}
		/>
		
		{fieldError &&
		<Form.Label className={`error`}>{fieldError}</Form.Label>
		}
		
	</>
	);
	
};


export default FormTextAreaFieldComponent;
