import React from "react";


import {Col, Row} from "react-bootstrap";


export const MainSectionContentComponent = ({children, props}) => {
	
	
	if (!children) return null;
	
	
	return (
		<Col as={`article`} xs={12} className={`main-section-content`} {...props}>
			<Row className={`main-section-box`}>
				{children}
			</Row>
		</Col>
	);
	
	
};


export default MainSectionContentComponent;
