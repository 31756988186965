import React, {useEffect, useRef} from "react";


import scrollToDomElement from "@Utilities/Scrolling";


import PageContainerComponent from "@Components/UI/Containers/PageContainer";


import HomePageNewsComponent from "@UIPages/Common/HomePage/News";


export const HomePageComponent = () => {
	
	
	const PAGE_CONTAINER_REF = useRef(null);
	
	useEffect(() => {
		scrollToDomElement(PAGE_CONTAINER_REF.current,window.innerWidth - 250);
	}, []);
	
	
	return (
		<PageContainerComponent forwardRef={PAGE_CONTAINER_REF} id={"home-page-component"} cssClasses={"home-page-component"}>
			
			<HomePageNewsComponent />
			
		</PageContainerComponent>
	);
	
};


export default HomePageComponent;
