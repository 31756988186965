import React from "react";


import {Col} from "react-bootstrap";


export const FormComponent = ({
	children = null,
	as = "aside",
	xs = 12, sm = 12, lg = 12,
	cssClasses = "",
}) => {
	
	
	cssClasses = "main-section-form" + (cssClasses ? ` ${cssClasses}` : "");
	
	
	return (
		<Col as={as} xs={xs} sm={sm} lg={lg} className={cssClasses}>
			{children}
		</Col>
	);
	
};


export default FormComponent;
