import React from "react";

import parseHTML from "html-react-parser";


import {Col} from "react-bootstrap";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


export const MainSectionTextComponent = ({
	textContent,
	xs = 12, sm = 12, lg = 12, xl = 12,
	as = "article", cssClasses = "",
	props
}) => {
	
	
	cssClasses = "main-section-text" + (cssClasses ? ` ${cssClasses}` : "");
	
	
	return (
		<Col as={as} xs={xs} sm={sm} lg={lg} xl={xl} className={cssClasses} {...props}>
		{textContent ?
			parseHTML(textContent)
		:
			<SingleContentLoaderComponent />
		}
		</Col>
	);
	
	
};


export default MainSectionTextComponent;
