import React, {useRef, useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import GLOBAL_REST_API_DATA from "@Globals/API";

import parse from "html-react-parser";


import {Redirect} from "react-router-dom";


import scrollToDomElement from "@Utilities/Scrolling";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import PageContainerComponent from "@Components/UI/Containers/PageContainer";
import PageHeaderContainerComponent from "@Components/UI/Containers/HeaderContainer";
import PageContentContainerComponent from "@Components/UI/Containers/ContentContainer";


const {
	URLS: {
		PAGES: {statistics_courses_page: {main_url: STATISTICS_COURSES_PAGE_URL}}
	}
} = GLOBAL_REST_API_DATA;


const COMPONENT_TEXTS = {
	PL: {}
};


export const CoursesPageComponent = () => {

	
	const DISPATCHER = useDispatch();
	
	
	const PAGE_CONTAINER_REF = useRef(null);
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage;
	
	const {} = COMPONENT_TEXTS[ACTIVE_LANGUAGE];
	
	
	const [STATISTICS_COURSES_CONTENT, SET_STATISTICS_COURSES_CONTENT] = useState(null);
	
	const GET_STATISTICS_COURSES_CONTENT = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const RESPONSE = await fetch(`${STATISTICS_COURSES_PAGE_URL}/`);
			const CONTENT = await RESPONSE.json();
			SET_STATISTICS_COURSES_CONTENT(CONTENT);
		} catch (e) {
			console.log(e);
			SET_STATISTICS_COURSES_CONTENT({error: true});
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	
	useEffect(() => {
		scrollToDomElement(PAGE_CONTAINER_REF.current, 300);
		GET_STATISTICS_COURSES_CONTENT();
		return () => {};
	}, []);
	
	
	if (!STATISTICS_COURSES_CONTENT) return <MainContentLoaderComponent />;
	
	
	if (STATISTICS_COURSES_CONTENT?.error) return <Redirect to={"/404"} />;
	
	
	const {
		trainings_page_title: statisticsCoursesPageTitle, trainings_page_content: statisticsCoursesPageContent
	} = STATISTICS_COURSES_CONTENT[0];
	
	
	return (
		<PageContainerComponent forwardRef={PAGE_CONTAINER_REF} id={"courses-page-component"}>
		
			<PageHeaderContainerComponent headerText={statisticsCoursesPageTitle || ""} />
			
			{!!statisticsCoursesPageContent &&
			<PageContentContainerComponent>
				{parse(statisticsCoursesPageContent || "")}
			</PageContentContainerComponent>
			}
			
		</PageContainerComponent>
	);
	
};


export default CoursesPageComponent;
