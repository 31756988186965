import React, {useEffect, useRef, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import GLOBAL_REST_API_DATA from "@Globals/API";


import {Redirect} from "react-router-dom";

import parse from "html-react-parser";


import {Col} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import PageContentContainerComponent from "@Components/UI/Containers/ContentContainer";
import PageContainerComponent from "@Components/UI/Containers/PageContainer";
import PageHeaderContainerComponent from "@Components/UI/Containers/HeaderContainer";


import ArticlePageImageComponent from "@UIPages/TextPages/Articles/Article/Image";
import ArticlePageFooterComponent from "@UIPages/TextPages/Articles/Article/Footer";


const {
	URLS: {
		PAGES: {articles_page: {single_article_page: {main_url: ARTICLE_PAGE_URL}}}
	}
} = GLOBAL_REST_API_DATA;


const COMPONENT_TEXTS = {
	PL: {}
};


export const ArticlePageComponent = ({match}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const PAGE_CONTAINER_REF = useRef(null);
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage;
	const {} = COMPONENT_TEXTS[ACTIVE_LANGUAGE];
	
	
	const [ARTICLE_PAGE_DATA, SET_ARTICLE_PAGE_DATA] = useState(null);
	
	const GET_ARTICLE_PAGE_DATA = async () => {
		
		const ARTICLE_SLUG = match?.params?.articleSlug;
		if (!ARTICLE_SLUG) return {error: true};
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		try {
			const RESPONSE = await fetch(`${ARTICLE_PAGE_URL}/${ARTICLE_SLUG}/`);
			const CONTENT = await RESPONSE.json();
			SET_ARTICLE_PAGE_DATA(CONTENT);
		} catch (e) {
			console.log(e);
			SET_ARTICLE_PAGE_DATA({error: true});
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
	};
	
	useEffect(() => {
		scrollToDomElement(PAGE_CONTAINER_REF.current)
		GET_ARTICLE_PAGE_DATA();
	}, []);
	
	
	if (!ARTICLE_PAGE_URL || ARTICLE_PAGE_DATA?.error) return <Redirect to={`/404`} />;
	
	
	if (!ARTICLE_PAGE_DATA) return <MainContentLoaderComponent loaderColor={"#17366E"} />;
	
	
	const {
		blog_page_title: blogPageTitle,
		blog_page_content: blogPageContent,
		blog_page_image: blogPageImage,
		blog_page_author: blogPageAuthor,
		blog_page_published_status: blogPagePublishedStatus,
		blog_page_create_date: blogPageCreatDate
	} = ARTICLE_PAGE_DATA;
	
	
	if (!blogPagePublishedStatus) return <Redirect to={`/404`} />;
	
	
	return (
		<PageContainerComponent forwardRef={PAGE_CONTAINER_REF} id={"article-page-component"}>
		
			<PageHeaderContainerComponent headerText={blogPageTitle} />
			
			{blogPageImage &&
			<ArticlePageImageComponent articleImage={blogPageImage} articleTitle={blogPageTitle} />
			}
			
			<PageContentContainerComponent cssClasses={"article-page-content-component"}>
				<Col xs={12} className={"article-page-content"}>
					{parse(blogPageContent || "")}
				</Col>
			</PageContentContainerComponent>
			
			{!!blogPageAuthor && !!blogPageCreatDate &&
			<ArticlePageFooterComponent articleAuthor={blogPageAuthor} articleDate={blogPageCreatDate}/>
			}
			
		</PageContainerComponent>
	);
	
};


export default ArticlePageComponent;
