import React from "react";


import {Row, Form} from "react-bootstrap";


export const FormRowComponent = ({
	as = Row, id = null,
	cssClasses = "",
	children,
	props
}) => {
	
	
	cssClasses = "section-form-row" + (cssClasses ? ` ${cssClasses}` : "");
	
	
	return (
		<Form.Group as={as} controlId={id} className={cssClasses} {...props}>
			{children}
		</Form.Group>
	)
	
};


export default FormRowComponent;
