import React from "react";


import {Col} from "react-bootstrap";


import {FaFacebookF, FaInstagram, FaTwitter, FaYoutube, IoLogoTiktok} from "react-icons/all";


const SOCIALS_ICONS = {
	facebook: FaFacebookF,
	twitter: FaTwitter,
	youtube: FaYoutube,
	instagram: FaInstagram,
	tiktok: IoLogoTiktok
};


export const SocialsSocialComponent = ({socialData}) => {
	
	
	if (!socialData) return null;
	
	const {
		social_active_status: socialActiveStatus,
		social_title: socialTitle,
		social_type: socialType,
		social_url: socialUrl
	} = socialData;
	
	
	const SocialIcon = SOCIALS_ICONS[socialType];
	
	
	if (!socialActiveStatus || !socialUrl) return null;
	
	
	return (
		<Col as={`li`} className={`header-socials-element`}>
			<a href={socialUrl} title={socialTitle} target={`_blank`} rel={`noreferrer noopener`} className={"socials-list-link"}>
				{!!SocialIcon && <SocialIcon />}
			</a>
		</Col>
	);
	
};


export default SocialsSocialComponent;
