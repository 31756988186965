import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";

import GLOBAL_REST_API_DATA from "@Globals/API";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";

import PageContentContainerComponent from "@Components/UI/Containers/ContentContainer";


import SponsorsSponsorComponent from "@UIParts/Sponsors/Sponsor";


const {
	URLS: {PARTNERS: {partners_list_page: {main_url: PARTNERS_LIST_URL}}}
} = GLOBAL_REST_API_DATA;


const COMPONENT_TEXTS = {
	PL: {header_text: "Partnerzy"}
};


export const ApplicationSponsorsComponent = () => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage;
	const {} = COMPONENT_TEXTS[ACTIVE_LANGUAGE];
	
	
	const [PARTNERS_LIST, SET_PARTNERS_LIST] = useState(null);
	
	const GET_PARTNERS_LIST = async () => {
		try {
			const RESPONSE = await fetch(PARTNERS_LIST_URL);
			const CONTENT = await RESPONSE.json();
			SET_PARTNERS_LIST(CONTENT);
		} catch (e) {
			console.log(e);
			SET_PARTNERS_LIST({error: true});
		}
	};
	
	useEffect(() => {
		GET_PARTNERS_LIST();
	}, []);
	
	
	if (!PARTNERS_LIST) return <SingleContentLoaderComponent xs={12} loaderElementsMargin={10} />;
	
	
	if (PARTNERS_LIST?.error || PARTNERS_LIST?.length === 0) return null;
	
	
	const carouselSettings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 6,
		slidesToScroll: 1,
		initialSlide: 0,
		autoplay: true,
		autoplaySpeed: 4000,
		pauseOnHover: true,
		// adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 6
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};
	
	
	const sponsorsListComponent = PARTNERS_LIST.map((sponsor, i) =>
		<SponsorsSponsorComponent key={i} sponsorData={sponsor} />
	);
	
	
	return (
		<PageContentContainerComponent cssClasses={`application-sponsors-component`}>
			
			<Slider {...carouselSettings} className={"sponsors-carousel-component"}>
				{sponsorsListComponent}
			</Slider>
		
		</PageContentContainerComponent>
	);
	
};


export default ApplicationSponsorsComponent;
