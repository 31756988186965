import React from "react";


import {Col, Row} from "react-bootstrap";


export const LeaguesListComponent = ({LEAGUES = null}) => {
	
	
	if (!LEAGUES) return null;
	
	
	const LEAGUES_LIST = LEAGUES.map((LEAGUE, i) => {
		
		const {
			league_name: LEAGUE_NAME,
			league_city: LEAGUE_CITY,
			league_domain_url: LEAGUE_DOMAIN_URL,
			league_url: LEAGUE_PAGE_URL
		} = LEAGUE;
		
		const LEAGUE_URL = LEAGUE_DOMAIN_URL ? `https://${LEAGUE_DOMAIN_URL}.plka.pl` : LEAGUE_PAGE_URL;
		
		return (
			<Col key={i} xs={12} className={"map-leagues-league"}>
				
				{LEAGUE_URL ?
				
				<a href={LEAGUE_URL} title={``} target={`_blank`} rel={`noreferrer nopener`}>
					<span>{LEAGUE_NAME}</span>
				</a>
					
					:
					
				<span>{LEAGUE_NAME}</span>
				
				}
				
			</Col>
		);
		
	});
	
	
	return (
		<Col xs={12} lg={3} className={"map-leagues-list"}>
			<Row className={"map-leagues-list-content"}>
				
				{LEAGUES_LIST}
				
			</Row>
		</Col>
	);
	
};


export default LeaguesListComponent;
