import GLOBAL_REST_API_DATA from "@Globals/API";


const {URLS: {getCsrfTokenURL}} = GLOBAL_REST_API_DATA;


export const getApiCsrfTokenRequest = async () => {
	
	if (!getCsrfTokenURL) return {error: true, code: 404, message: "No Token URL"};
	
	try {
		
		const response = await fetch(
			getCsrfTokenURL
		);
		const responseContent = await response.json();
		
		const {csrfToken} = responseContent;
		return csrfToken;
		
	} catch (e) {
		return {error: true, code: 500, message: e.message};
	}
	
	
};


export default getApiCsrfTokenRequest;
