import {CSRF_COOKIE} from "@Actions";


const authenticationInitialState = {
	csrfCookie: null
};


export const AuthenticationReducer = (state = authenticationInitialState, action) => {
	
	switch (action.type) {
		
		case CSRF_COOKIE:
			return {...state, csrfCookie: action.cookie};
		
		default:
			return state;
			
	}
	
};


export default AuthenticationReducer;
