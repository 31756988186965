import React from "react";

import parseHTML from "html-react-parser";


import {NavLink} from "react-router-dom";


import {Row, Col} from "react-bootstrap";


import noImage from "@Images/no-image.png";


export const ArticlesListItemComponent = ({articleData}) => {
	
	
	if (!articleData) return null;
	
	
	const {
		// blog_page_id: blogPageID,
		blog_page_title: blogPageTitle,
		blog_page_slug: blogPageSlug,
		blog_page_short_content: blogPageShortContent,
		// blog_page_content: blogPageContent,
		blog_page_image: blogPageImage,
		blog_page_author: blogPageAuthor,
		blog_page_published_status: blogPagePublishedStatus,
		blog_page_create_date: blogPageCreateDate
	} = articleData;
	
	
	if (!blogPagePublishedStatus) return null;
	
	
	return (
		<Row as={"article"} className={"articles-list-item"}>
		
			<Col xs={12} lg={3} className={"article-image"}>
				<NavLink to={`/aktualnosci/${blogPageSlug}`} title={`Przejdź do artykułu`}>
					<img src={blogPageImage || noImage} alt={blogPageTitle} />
				</NavLink>
			</Col>
			
			<Col xs={12} lg={{span: 8, offset: 1}} className={"article-content"}>
				<h4 className={"article-title"}>
					<NavLink to={`/aktualnosci/${blogPageSlug}`} title={`Przejdź do artykułu`}>
						<span>{blogPageTitle}</span>
					</NavLink>
				</h4>
				<div className={"article-short-content"}>
					<NavLink to={`/aktualnosci/${blogPageSlug}`} title={`Przejdź do artykułu`}>
						{parseHTML(blogPageShortContent)}
					</NavLink>
				</div>
				<h6 className={"article-information"}>
					<span className={"article-author"}>{blogPageAuthor}</span>
					<span className={"article-date"}>{(new Date(blogPageCreateDate)).toLocaleDateString()}</span>
				</h6>
			</Col>
			
		</Row>
	);
	
};


export default ArticlesListItemComponent;
