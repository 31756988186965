import React from 'react';
import ReactDOM from 'react-dom';


import reportWebVitals from './reportWebVitals';


import GlobalDataContext, {GLOBAL_DATA} from "@Contexts/Globals";

import {Provider as ApplicationStoreProvider} from "react-redux";
import ApplicationStore from "@Store";


import Application from "./Application";


import sha256 from 'crypto-js/sha256';

const AUTH = localStorage.getItem("PLKA_AUTH");


const RENDER = () => {
    ReactDOM.render(
        <React.StrictMode>
            <GlobalDataContext.Provider value={GLOBAL_DATA}>
                <ApplicationStoreProvider store={ApplicationStore}>
                    <Application />
                </ApplicationStoreProvider>
            </GlobalDataContext.Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
};

if (sha256(process.env.REACT_APP_SECRET_CODE).toString() === AUTH) {
    RENDER();
} else {
    const PASS = prompt("Restricted AREA");
    if (PASS === process.env.REACT_APP_SECRET_CODE) {
        localStorage.setItem("PLKA_AUTH", sha256(PASS));
        RENDER();
    }
}


reportWebVitals();
