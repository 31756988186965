import React from "react";
import {useSelector} from "react-redux";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";



import PageContentContainerComponent from "@Components/UI/Containers/ContentContainer";


const COMPONENT_TEXTS = {
	PL: {
		privacy_policy_title_text: "Polityka Prywatności",
		regulation_title_text: "Regulamin"
	}
};


const COMPONENT_URLS = {
	PL: {
		privacy_policy_page_url: "polityka-prywatnosci",
		regulations_page_url: "regulamin",
	}
}


export const ApplicationCopyrightsComponent = () => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage || "PL";
	
	const {
		privacy_policy_title_text: privacyPolicyTitleText, regulation_title_text: regulationTitleText
	} = COMPONENT_TEXTS[ACTIVE_LANGUAGE];
	
	const {
		privacy_policy_page_url: privacyPolicyPageURL, regulations_page_url: regulationsPageURL
	} = COMPONENT_URLS[ACTIVE_LANGUAGE];
	
	
	return (
		<PageContentContainerComponent as={"aside"} id={"application-copyrights-component"} cssClasses={"application-copyrights-component"}>
			
			<Col xs={12} lg={4} className={`copyrights-links`}>
				<NavLink to={`/${privacyPolicyPageURL}`} title={privacyPolicyTitleText}>
					<span>{privacyPolicyTitleText}</span>
				</NavLink>
				<NavLink to={`/${regulationsPageURL}`} title={regulationTitleText}>
					<span>{regulationTitleText}</span>
				</NavLink>
			</Col>
			
			<Col xs={12} lg={4} className={`copyrights-information text-center`}>
				<span className={`copyrights-information-label`}>copyrights</span>
				<span className={`copyrights-information-value`}>{`©2021 PLKA`}</span>
			</Col>
			
			<Col xs={12} lg={{span: 3, offset: 1}} className={`copyrights-developers`}>
				<span className={`copyrights-developers-label`}>{`Developed by`}:</span>
				<a href={`https://webpros.pl`} title={`Developed by`} target={`_blank`} rel={`noreferrer noopener`}>webpros.pl</a>
			</Col>
		
		</PageContentContainerComponent>
	);
	
};


export default ApplicationCopyrightsComponent;
