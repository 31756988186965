import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import GLOBAL_REST_API_DATA from "@Globals/API";


import scrollToDomElement from "@Utilities/Scrolling";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import PageContainerComponent from "@Components/UI/Containers/PageContainer";
import PageHeaderContainerComponent from "@Components/UI/Containers/HeaderContainer";
import PageContentContainerComponent from "@Components/UI/Containers/ContentContainer";


import PartnersPartnerComponent from "@UIPages/TextPages/Common/Partners/Partner";


const {
	URLS: {PARTNERS: {partners_list_page: {main_url: PARTNERS_LIST_URL}}}
} = GLOBAL_REST_API_DATA;


const COMPONENT_TEXTS = {
	PL: {header_text: "Partnerzy"}
};


export const PartnersPageComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const PAGE_CONTAINER_REF = useRef(null);
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage;
	const {header_text: headerText} = COMPONENT_TEXTS[ACTIVE_LANGUAGE];
	
	
	const [PARTNERS_LIST, SET_PARTNERS_LIST] = useState(null);
	
	const GET_PARTNERS_LIST = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const RESPONSE = await fetch(PARTNERS_LIST_URL);
			const CONTENT = await RESPONSE.json();
			SET_PARTNERS_LIST(CONTENT);
		} catch (e) {
			console.log(e);
			SET_PARTNERS_LIST({error: true});
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	useEffect(() => {
		scrollToDomElement(PAGE_CONTAINER_REF.current, 300);
		GET_PARTNERS_LIST();
	}, []);
	
	
	
	if (!PARTNERS_LIST) return <MainContentLoaderComponent loaderElementsMargin={10} loaderText={headerText} />;
	
	
	const partnersListComponent = PARTNERS_LIST.map((partner, i) =>
		<PartnersPartnerComponent key={i} partnerData={partner} />
	);
	
	
	return (
		<PageContainerComponent forwardRef={PAGE_CONTAINER_REF} as={`main`} id={"partners-page-component"}>
			
			<PageHeaderContainerComponent headerText={headerText} />
			
			<PageContentContainerComponent cssClasses={`partners-page-component`}>
				{partnersListComponent}
			</PageContentContainerComponent>
			
		</PageContainerComponent>
	);
	
};


export default PartnersPageComponent;
