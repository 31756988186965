import React, {useState} from "react";

import GLOBAL_REST_API_DATA from "@Globals/API";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import parseHTML from "html-react-parser";


import {Col, Row} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";
import FormMessageComponent from "@Elements/Sections/Forms/Messages/Information";

import AlreadyHasLeagueFormComponent from "@UIPages/Common/JoinUsPage/AlreadyLeague/Form";


const {URLS: {EMAILS: {sendEmailURL: SEND_EMAIL_URL}}} = GLOBAL_REST_API_DATA;


export const AlreadyHasLeagueComponent = ({componentContent = null}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	// const CSRF_COOKIE = useSelector(state => state?.authentication)?.csrfCookie;
	
	
	const [alreadyHasLeagueMessageSentStatus, setAlreadyHasLeagueMessageSentStatus] = useState(false);
	
	
	const sendAlreadyHasLeagueMessageRequest = async (senderEmail, messageContent) => {
		
		if (!SEND_EMAIL_URL) return {error: true, message: "Błąd wysyłania wiadomości (401)"};
		
		let RESPONSE = {};
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		// const HEADERS = {"X-CSRFToken": CSRF_COOKIE};
		
		const FORM_DATA = new FormData();
		FORM_DATA.append("message_type", "join_us_has_league");
		FORM_DATA.append("message_email", senderEmail);
		FORM_DATA.append("message_content", `Formularz: Dołącz do nas, mam już ligę\nEmail: ${senderEmail || ""}\n${messageContent || ""}`);
		
		try {
			const response = await fetch(SEND_EMAIL_URL, {
				method: "POST", body: FORM_DATA
			});
			const responseContent = await response.json();
			if (responseContent.success) {
				RESPONSE = {success: true};
				setAlreadyHasLeagueMessageSentStatus(true);
			} else {
				RESPONSE = {error: true};
			}
		} catch (e) {
			RESPONSE = {error: true};
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
		return RESPONSE;
		
	};
	
	
	if (!componentContent) return <MainContentLoaderComponent />;
	
	
	return (
		<Row className={"already-has-league-component"}>
			
			<Col xs={12} className={"already-has-league-content text-page-content"}>
				{parseHTML(componentContent || "")}
			</Col>
			
			{!alreadyHasLeagueMessageSentStatus &&
			<AlreadyHasLeagueFormComponent
				sendAlreadyHasLeagueMessageRequestHandler={sendAlreadyHasLeagueMessageRequest}
			/>
			}
			
			{alreadyHasLeagueMessageSentStatus &&
			<FormMessageComponent
				messageText={`Wiadomość została wysłana`}
				lg={12}
				variant={`success`}
			/>
			}
			
		</Row>
	);
	
};


export default AlreadyHasLeagueComponent;
