import React from "react";


export const GLOBAL_DATA = {
	restApiData: {
		// main_rest_api_url: "http://127.0.0.1:8020/api/v1/",
		mainRestApiURL: "https://plka.webprosdev.usermd.net/api/v1",
	},
	site_pages_data: {
		common_pages: {},
		authenticated_pages: {},
		not_authenticated_pages: {},
		error_pages: {},
	}
};


const GlobalDataContext = React.createContext(null);


export default GlobalDataContext;
