import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";


import {Alert, Col} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import PageContentContainerComponent from "@Components/UI/Containers/ContentContainer";


const COMPONENT_TEXTS = {
	PL: {page_not_found_error_message_text: "Nie znaleziono strony"}
};


export const E404PageComponent = () => {
	
	
	const PAGE_CONTAINER_REF = useRef(null);
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage;
	
	const {
		page_not_found_error_message_text: pageNotFoundErrorMessageText
	} = COMPONENT_TEXTS[ACTIVE_LANGUAGE];
	
	
	useEffect(() => {
		scrollToDomElement(PAGE_CONTAINER_REF.current, 300)
	}, []);
	
	
	return (
		<PageContentContainerComponent forwardRef={PAGE_CONTAINER_REF} as={`main`} cssClasses={`e404-page-component error-page-component`}>
		
			<Col as={Alert} xs={12} variant={"danger"}>
				<span>{pageNotFoundErrorMessageText}</span>
			</Col>
		
		</PageContentContainerComponent>
	);
	
};


export default E404PageComponent;
