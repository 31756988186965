import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import GLOBAL_REST_API_DATA from "@Globals/API";


import {Container, Row} from "react-bootstrap";


import NewsActiveArticleComponent from "@UIPages/Common/HomePage/News/Active";
import NewsArticlesListComponent from "@UIPages/Common/HomePage/News/List";


const {
	URLS: {
		PAGES: {articles_page: {articles_list_page: {main_url: ARTICLES_LIST_PAGE_URL}}}
	}
} = GLOBAL_REST_API_DATA;


export const HomePageNewsComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const [HOME_PAGE_NEWS, SET_HOME_PAGE_NEWS] = useState(null);
	
	const GET_HOME_PAGE_NEWS = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const RESPONSE = await fetch(`${ARTICLES_LIST_PAGE_URL}/?results_limit=4&all_results=true&blog_page_published_status=1`);
			const CONTENT = await RESPONSE.json();
			SET_HOME_PAGE_NEWS(CONTENT);
			if (CONTENT?.length) {
				const {blog_page_slug: articleSlug} = CONTENT[0]
				setHomePageActiveArticle(articleSlug);
			}
		} catch (e) {
			console.log(e);
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	
	const HomePageArticles = !HOME_PAGE_NEWS ? null : Object.fromEntries(HOME_PAGE_NEWS.map(article => {
		
		const {
			blog_page_slug: articleSlug, blog_page_title: articleTitle,
			blog_page_short_content: articleShortContent, blog_page_image: articleImage
		} = article;
		
		return [
			articleSlug, {articleSlug, articleTitle, articleShortContent, articleImage}
		];
		
	}));
	
	
	const [homePageActiveArticle, setHomePageActiveArticle] = useState( "");
	
	
	useEffect(() => {
		GET_HOME_PAGE_NEWS();
	}, []);
	
	
	if (HomePageArticles && Object.entries(HomePageArticles)?.length === 0) return null;
	
	
	return (
		<Container fluid={true} className={`home-page-news-component`}>
			<Container className={"home-page-news-container"}>
				<Row className={"home-page-news-content"}>
				
					<NewsActiveArticleComponent
						activeArticleData={HomePageArticles ? HomePageArticles[homePageActiveArticle] : null}
					/>
					
					<NewsArticlesListComponent
						articlesList={HomePageArticles ? Object.values(HomePageArticles) : null}
						activeArticle={homePageActiveArticle}
						activeArticleHandler={setHomePageActiveArticle}
					/>
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default HomePageNewsComponent;
