import React from "react";

import parseHTML from "html-react-parser";


import {Col} from "react-bootstrap";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


export const MainSectionHeaderComponent = ({
	headerText = null,
	xs = 12, sm = 12, lg = 12, xl = 12,
	as = "h1", cssClasses = "",
	props
}) => {
	
	cssClasses = `main-section-header` + (cssClasses ? ` ${cssClasses}` : "");
	
	return (
		<Col as={as} xs={xs} sm={sm} lg={lg} xl={xl} className={cssClasses} {...props}>
		{headerText ?
			<span>{parseHTML(headerText)}</span>
			:
			<SingleContentLoaderComponent loaderElementsMargin={10} />
		}
		</Col>
	);
	
};


export default MainSectionHeaderComponent;
