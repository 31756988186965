import React, {useState} from "react";

import {useSelector} from "react-redux";


import {Button, Col, Form} from "react-bootstrap";


import isEmail from "validator/es/lib/isEmail";


export const AlreadyHasLeagueFormComponent = ({sendAlreadyHasLeagueMessageRequestHandler = null}) => {
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus;
	
	
	const [alreadyHasLeagueMessageEmail, setAlreadyHasLeagueMessageEmail] = useState("");
	const [alreadyHasLeagueMessageContent, setAlreadyHasLeagueMessageContent] = useState("Chcę dołączyć do PLKA");
	
	const [alreadyHasLeagueMessageErrors, setAlreadyHasLeagueMessageErrors] = useState({});
	
	
	const changeAlreadyHasLeagueMessageEmail = (email) => {
		if (isEmail(email)) setAlreadyHasLeagueMessageErrors({
			...alreadyHasLeagueMessageErrors, emailError: null
		})
		setAlreadyHasLeagueMessageEmail(email);
	};
	
	const changeAlreadyHasLeagueMessageContent = (content) => {
		if (content.length >= 10) setAlreadyHasLeagueMessageErrors({
			...alreadyHasLeagueMessageErrors, contentError: null
		})
		setAlreadyHasLeagueMessageContent(content);
	};
	
	const focusAlreadyHasLeagueMessageContentHandler = (text) => {
		if (text === "Chcę dołączyć do PLKA") setAlreadyHasLeagueMessageContent("");
	};
	
	const blurAlreadyHasLeagueMessageContentHandler = (text) => {
		if (text === "") setAlreadyHasLeagueMessageContent("Chcę dołączyć do PLKA");
	};
	
	
	const submitAlreadyHasLeagueFormHandler = async () => {
		
		const formErrors = {};
		
		if (!isEmail(alreadyHasLeagueMessageEmail)) formErrors.emailError = "Nieprawidłowy adres email"
		if (alreadyHasLeagueMessageContent.length < 10) formErrors.contentError = "Treść wiadomości jest zbyt krótka"
		
		setAlreadyHasLeagueMessageErrors(formErrors);
		
		if (!!Object.entries(formErrors).length) return null;
		
		
		const RESPONSE = await sendAlreadyHasLeagueMessageRequestHandler(
			alreadyHasLeagueMessageEmail, alreadyHasLeagueMessageContent
		);
		
		if (RESPONSE?.error) {
			setAlreadyHasLeagueMessageErrors({requestError: true})
		}
		
		
	};
	
	
	return (
		<Col xs={12} className={"already-has-league-message"}>
			
			<h2 className={"join-us-message-header"}>
				<span>Dołącz do nas</span>
			</h2>
			
			<Form.Group className={"join-us-message-field"}>
				<Form.Control
					type={"email"}
					value={alreadyHasLeagueMessageEmail}
					onChange={e => changeAlreadyHasLeagueMessageEmail(e.target.value)}
					placeholder={"Email"}
					disabled={REST_API_REQUEST_STATUS}
				/>
				{alreadyHasLeagueMessageErrors?.emailError &&
				<Form.Label className={"error"}>{alreadyHasLeagueMessageErrors.emailError}</Form.Label>
				}
			</Form.Group>
			
			<Form.Group className={"join-us-message-row"}>
				<Form.Control
					as={"textarea"}
					value={alreadyHasLeagueMessageContent}
					onChange={e => changeAlreadyHasLeagueMessageContent(e.target.value)}
					onFocus={e => focusAlreadyHasLeagueMessageContentHandler(e.target.value)}
					onBlur={e => blurAlreadyHasLeagueMessageContentHandler(e.target.value)}
					placeholder={"Treść Wiadomości"}
					disabled={REST_API_REQUEST_STATUS}
				/>
				{alreadyHasLeagueMessageErrors?.contentError &&
				<Form.Label className={"error"}>{alreadyHasLeagueMessageErrors.contentError}</Form.Label>
				}
			</Form.Group>
			
			<Form.Group className={"join-us-message-row join-us-message-submit text-center"}>
				<Button variant={"primary"} onClick={submitAlreadyHasLeagueFormHandler} disabled={REST_API_REQUEST_STATUS}>
					<span>{`Wyślij`}</span>
				</Button>
				{alreadyHasLeagueMessageErrors?.requestError &&
				<Form.Label>{`Błąd wysyłania wiadomości`}</Form.Label>
				}
			</Form.Group>
			
		</Col>
	);
	
};


export default AlreadyHasLeagueFormComponent;
