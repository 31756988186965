import React, {useEffect, useState} from "react";


import GLOBAL_REST_API_DATA from "@Globals/API";


import {Row, Col} from "react-bootstrap";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";

import SocialsSocialComponent from "@UIParts/Header/Socials/Social";


const {URLS: {SOCIALS: {socialsLinks: SOCIALS_LINKS_API_URL}}} = GLOBAL_REST_API_DATA;


export const HeaderSocialsComponent = ({xs = 12, lg = 3}) => {
	
	
	const [SOCIALS_LIST, SET_SOCIALS_LIST] = useState(null);
	
	const GET_SOCIALS_LIST = async () => {
		try {
			const RESPONSE = await fetch(SOCIALS_LINKS_API_URL);
			const CONTENT = await RESPONSE.json();
			SET_SOCIALS_LIST(CONTENT);
		} catch (e) {
			console.log(e);
			SET_SOCIALS_LIST({error: true});
		}
	};
	
	useEffect(() => {
		GET_SOCIALS_LIST();
	}, []);
	
	
	if (!SOCIALS_LIST) {
		return (
			<SingleContentLoaderComponent
				xs={xs} lg={lg} xl={lg}
				cssClasses={"header-socials-component"}
				loaderColor={"#FFF"}
			/>
		);
	}
	
	if (SOCIALS_LIST.length === 0 || SOCIALS_LIST?.error) return null;
	
	
	const socialsListComponent = SOCIALS_LIST.map((social, i) =>
		<SocialsSocialComponent key={i} socialData={social} />
	);
	
	
	return (
		<Col as={`aside`} xs={xs} lg={lg} className={`header-socials-component`}>
			<Row as={`ul`} className={`header-socials-list`}>
				{socialsListComponent}
			</Row>
		</Col>
	);
	
};


export default HeaderSocialsComponent;
