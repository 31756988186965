import React from "react";


import {Col, Container, Row} from "react-bootstrap";


export const ArticlePageFooterComponent = ({articleAuthor, articleDate}) => {
	
	
	return (
		<Container fluid={true} className={`article-page-footer-component`}>
			<Container className={`article-page-footer-container`}>
				<Row className={`article-page-footer-content`}>
					
					<Col xs={12} className={`article-page-footer`}>
						<span className={`article-author`}>{articleAuthor}</span>
						<span className={`article-date`}>{(new Date(articleDate)).toLocaleDateString()}</span>
					</Col>
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default ArticlePageFooterComponent;
