import {combineReducers} from "redux";

import AuthenticationReducer from "@Reducers/Authentication";
import LocalizationReducer from "@Reducers/Localization";
import InterfaceReducer from "@Reducers/Interface";


const ApplicationReducer = combineReducers({
	authentication: AuthenticationReducer,
	localization: LocalizationReducer,
	interface: InterfaceReducer
});


export default ApplicationReducer;
