import React, {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import GLOBAL_REST_API_DATA from "@Globals/API";


import {Col} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import ArticlesListItemComponent from "@UIPages/TextPages/Articles/List/Item";


const {
	URLS: {
		PAGES: {articles_page: {articles_list_page: {main_url: ARTICLES_PAGE_URL}}}
	}
} = GLOBAL_REST_API_DATA;


const COMPONENT_TEXTS = {
	PL: {}
};


export const ArticlesListPageComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage;
	const {} = COMPONENT_TEXTS[ACTIVE_LANGUAGE];
	
	
	const [ARTICLES_LIST, SET_ARTICLES_LIST] = useState(null);
	
	const GET_ARTICLES_LIST = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(`${ARTICLES_PAGE_URL}/`);
			const content = await response.json();
			SET_ARTICLES_LIST(content?.results);
		} catch (e) {
			console.log(e);
			SET_ARTICLES_LIST({error: true});
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	useEffect(() => {
		GET_ARTICLES_LIST();
	}, []);
	
	
	if (!ARTICLES_LIST) return <MainContentLoaderComponent loaderColor={"#17366E"} />;
	
	
	if (ARTICLES_LIST.error) return null;
	
	
	const ArticlesList = ARTICLES_LIST.map((article, i) =>
		<ArticlesListItemComponent key={i} articleData={article} />
	);
	
	
	return (
		<Col xs={12} className={"articles-list-component"}>
			{ArticlesList}
		</Col>
	);
	
};


export default ArticlesListPageComponent;
