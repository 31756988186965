import React from "react";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";


import plkaLogo from "@Assets/Images/plka_logo_color.png";


export const HeaderLogoComponent = ({xs = 9, lg = 2}) => {
	
	
	return (
		<Col as={`aside`} xs={xs} lg={lg} className={`header-logo-component`}>
			<NavLink to={`/`} title={"PLKA"}>
				<img src={plkaLogo} alt={"PLKA"} />
			</NavLink>
		</Col>
	);
	
};


export default HeaderLogoComponent;
