import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import GLOBAL_REST_API_DATA from "@Globals/API";

import {Col, Row} from "react-bootstrap";


import CreateNewLeagueFormComponent from "@UIPages/Common/JoinUsPage/CreateLeague/Form";
import FormMessageComponent from "@Elements/Sections/Forms/Messages/Information";


const {URLS: {EMAILS: {sendEmailURL: SEND_EMAIL_URL}}} = GLOBAL_REST_API_DATA;


export const CreateNewLeagueComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const [messageSentStatus, setMessageSentStatus] = useState(false);
	
	
	const sendMessageRequestHandler = async (formData) => {
		
		if (!SEND_EMAIL_URL) return {error: true, message: "Błąd wysyłania wiadomości (401)"};
		
		let RESPONSE = {};
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		const {
			leagueVillage, basketballPlayedStatus, rulesKnowledgeStatus, rulesKnowledgeStatusLevel,
			eventsExperience, timeToSpentForLeague, timeSpentType,
			computerKnowledgeLevel, statisticsKnowledge,
			firstName, lastName, emailAddress, phoneNumber
		} = formData;
		
		let messageContent = `Formularz: Dołącz do nas, chcę stworzyć nową ligę\n\n`;
		messageContent += `Imię i nazwisko: ${firstName} ${lastName}\n`;
		messageContent += `Email: ${emailAddress}\n`;
		messageContent += `Numer Telefonu: ${phoneNumber}\n\n`;
		messageContent += `Miejscowość: ${leagueVillage}\n`;
		messageContent += `Gram / Grałem w koszykówkę: ${basketballPlayedStatus === 1 ? "TAK" : "NIE"}\n`
		messageContent += `Znam zasady: ${rulesKnowledgeStatus === 1 ? "TAK" : "NIE"}` + (rulesKnowledgeStatus === 1 ? ` - poziom: ${rulesKnowledgeStatusLevel}\n` : "\n\n");
		messageContent += `Doświadczenie w organizacji imprez: ${eventsExperience}\n`;
		messageContent += `Czas który chcę przeznaczyć na ligę: ${timeToSpentForLeague}\n`;
		messageContent += `Główne zajęcie: ${timeSpentType === 1 ? "TAK" : "NIE"}\n\n`;
		messageContent += `Poziom obsługi komputera: ${computerKnowledgeLevel === 1 ? "podstawowy" : (computerKnowledgeLevel === 2 ? "średnio-zaawansowany" : "zaawansowany")}\n`;
		messageContent += `Wiedza odnośnie statystyk: ${statisticsKnowledge}`
		
		const FORM_DATA = new FormData();
		
		FORM_DATA.append("message_type", "join_us_create_league");
		FORM_DATA.append("message_email", emailAddress);
		FORM_DATA.append("message_content", messageContent);
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		try {
			const response = await fetch(SEND_EMAIL_URL, {
				method: "POST", body: FORM_DATA
			});
			const responseContent = await response.json();
			console.log(responseContent)
			if (responseContent.success) {
				RESPONSE = {success: true};
				setMessageSentStatus(true);
			} else {
				RESPONSE = {error: true};
			}
		} catch (e) {
			return {error: true};
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
		return RESPONSE;
	
	};
	
	return (
		<Row className={"create-new-league-component"}>
			
			<Col xs={12} className={"create-new-league-header"}>
				<span>{`Wyślij nam potrzebne informacje`}</span>
			</Col>
			
			{!messageSentStatus &&
			<CreateNewLeagueFormComponent sendMessageRequestHandler={sendMessageRequestHandler} />
			}
			
			{messageSentStatus &&
			<FormMessageComponent
				messageText={`Wiadomość została wysłana`}
				lg={12}
				variant={`success`}
			/>
			}
			
		</Row>
		
	);
	
};


export default CreateNewLeagueComponent;
