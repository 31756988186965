export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";


export const CHANGE_LANGUAGE_ACTION = (languageCode) => {
	return {
		type: CHANGE_LANGUAGE,
		languageCode: languageCode
	};
};


export default CHANGE_LANGUAGE_ACTION;
